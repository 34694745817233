<template>
  <div class="login-container">
    <el-card>
      <!-- 教研平台 -->
      <template #header>
        <h2 class="title">{{ title }}</h2>
      </template>
      <!-- 登录表单 -->
      <!-- 左侧图片 -->
      <div class="logo-box"></div>
      <!-- 右侧 logo表单区域 -->
      <div class="logo-form" :class="title.includes('柳州') ? 'lzclass' : ''">
        <h1>
          <img :src="url" alt="logo" />
        </h1>
        <el-form
          ref="form"
          :model="loginForm"
          :rules="formRules"
          :hide-required-asterisk="true"
          class="form-left"
        >
          <el-form-item prop="account">
            <el-input v-model.trim="loginForm.account" placeholder="账号" clearable>
              <template #prefix>
                <el-icon class="el-input__icon">
                  <user />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model.trim="loginForm.password"
              placeholder="密码"
              clearable
              show-password
            >
              <template #prefix>
                <el-icon class="el-input__icon">
                  <lock />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <!-- 登录按钮-记住用户名复选框 -->
          <el-form-item>
            <el-checkbox v-model="rememberChecked" label="记住我"></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="loginSubmit" :loading="loading"
              >登 录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 可选登录入口 -->
    <div
      v-if="imgSrcList.length > 0"
      class="img-wrap"
      :class="imgSrcList.length == 1 ? 'img-wrap1' : ''"
    >
      <img
        :class="imgSrcList.length == 1 ? 'img-1' : ''"
        v-for="item in imgSrcList"
        :src="item.src"
        title="登录入口"
        @click="clickImg(item.url)"
      />
    </div>
    <div class="login-card" v-if="showCard">
      <h3>第三方授权登录中</h3>
      <div v-loading="loading"></div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, watch, nextTick } from "vue";
import { getUname, setUname, setPwd } from "@/utils/auth";
import { login, thirdLogin, thirdTwo } from "@/api/user";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import * as ENVS from "@/env";
import router from "@/router";
import axios from "axios";

export default {
  setup() {
    // 定义使用vuex
    const store = useStore();
    const state = reactive({
      account: "",
      title: ENVS.TITLE,
      normalLog: true,
      url: require("@/assets/" + ENVS.LOGOFILE),
      loading: false, // 按钮加载中
      loginForm: {
        // 表单绑定数据
        account: "",
        password: "",
      },
      rememberChecked: false, // 复选框绑定状态
      imgSrcList: [],
      imgUrlList: ENVS.IMGURLARR,
      infoList: [],
      loginInfo: [],
      timeOut: 10,
      repeatBtn: 0,
      showCard: false,
      thirdList: [1, 2], //第三方登录参数
    });

    if (router.currentRoute.value.query) {
      if (state.thirdList.indexOf(Number(router.currentRoute.value.query.tp)) != -1) {
        state.showCard = true;

        setTimeout(() => {
          thirdThing(router.currentRoute.value.query.tk);
        }, 0);
      }
    }

    watch(
      () => router.currentRoute.value.query,
      () => {
        if (state.thirdList.indexOf(Number(router.currentRoute.value.query.tp)) != -1) {
          state.showCard = true;
          setTimeout(() => {
            thirdThing(router.currentRoute.value.query.tk);
          }, 0);
        }
      }
    );

    //  可选登录入口--请求图片
    if (state.title.includes("衡阳")) {
      (async function getImgFn() {
        for (let i = 0; i < state.imgUrlList.length; i++) {
          try {
            const data = await axios.get(state.imgUrlList[i]);
            // console.log(data);
            if (data.status == 200) {
              state.imgSrcList.push({
                src: data.config.url,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (state.imgSrcList.length == 1) {
          state.imgSrcList[0]["url"] = "http://hyjy.hhyedu.com.cn:8088/xfaqjy/";
        } else if (state.imgSrcList.length == 2) {
          state.imgSrcList[0]["url"] = "http://hyjy.hhyedu.com.cn:8088/xfaqjy/";
          state.imgSrcList[1]["url"] = "http://hyjy.hhyedu.com.cn:8088/aqjy/";
        }
      })();
    } else {
      state.imgSrcList = [];
    }

    // 图片链接跳转
    const clickImg = (url) => {
      window.open(url, "_blank");
    };

    // 定义表单校验规则
    const formRules = {
      account: [
        {
          required: true,
          message: "请输入登录账号",
          trigger: "blur",
        },
        {
          min: 5,
          max: 20,
          message: "用户名长度需要在5到20个字符",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        // {
        //   min: 6,
        //   max: 11,
        //   message: "密码长度需要在6到11个字符",
        //   trigger: "blur",
        // },
      ],
    };

    // 回车登录
    document.onkeydown = function () {
      let key = window.event.keyCode;
      if (key == 13) {
        loginSubmit();
      }
    };

    // 获取表单组件实例 登录验证
    const form = ref(null);
    const loginSubmit = () => {
      form.value.validate(async (isOK) => {
        if (isOK) {
          // 表单校验成功再发起登录请求
          try {
            var a = 0;
            state.loading = true; // 让按钮处于加载中状态 避免连续多次请求
            state.infoList = JSON.parse(sessionStorage.getItem("loginUser"));

            if (state.infoList !== null) {
              const map2 = state.infoList.map((x) => {
                if (x.account == state.loginForm.account) {
                  if (x.time == 5) {
                    a = 1;
                    ElMessage.warning("登录错误次数已达上限，请5分钟后再重新登录");
                    state.loading = false; // 请求之后 失败成功都关闭登录按钮加载中状态
                    form.value.resetFields(); // 重置表单

                    // 重复倒计时
                    if (state.repeatBtn == 0) {
                      var outUser = setInterval(() => {
                        state.repeatBtn = 1;
                        state.timeOut--;
                        if (state.timeOut <= 0) {
                          state.repeatBtn = 0;
                          x.time = 4;
                          sessionStorage.setItem(
                            "loginUser",
                            JSON.stringify(state.infoList)
                          );
                          state.timeOut = 300;
                          clearInterval(outUser);
                        }
                      }, 1000);
                    }
                  }
                }
              });

              if (a == 1) {
                return;
              }
            }

            // 登录请求
            const { data: res } = await login(state.loginForm);

            if (res.code === 0) {
              state.infoList = JSON.parse(sessionStorage.getItem("loginUser"));
              if (state.infoList !== null) {
                const map3 = state.infoList.map((x) => {
                  if (x.account == state.loginForm.account) {
                    x.time = 0;
                  }
                  return x;
                });
              }

              sessionStorage.setItem("loginUser", JSON.stringify(state.infoList));

              // code为0 验证成功
              // 保存 用户信息 和 token
              store.commit("setLoginInfo", res.data);
              store.commit("setSsoToken", res.data.userInfo.ssoToken);

              // 如果勾选上记住用户名 - 保存7天
              if (state.rememberChecked) {
                setUname(state.loginForm.account, 7);
              }

              if (res.data.userInfo.isReal) {
                // 0606实名验证
                if (res.data.userInfo.needRMCert) {
                  // 0606完善实名验证
                  setPwd(state.loginForm.password);
                  router.push("/idVerify");
                } else {
                  router.push("/sso");
                  ElMessage.success("登录成功");
                }
              } else {
                // 无实名验证按原逻辑处理
                router.push("/sso");
                ElMessage.success("登录成功");
              }
            } else {
              var a = [];
              state.loginInfo = JSON.parse(sessionStorage.getItem("loginUser"));
              // 加入登录账号和次数
              a.push({
                account: state.loginForm.account,
                time: 0,
              });

              // 假设用户刷新后的处理
              if (state.loginInfo !== null) {
                state.loginInfo.forEach((item) => {
                  if (item.account == a[0].account) {
                    // item.time + 1
                    return;
                  } else {
                    state.loginInfo.push(a[0]);
                  }
                });
              } else {
                state.loginInfo = a;
              }

              // 去重 排序
              const map = new Map();
              state.loginInfo.forEach((item) => {
                if (!map.has(item.account)) {
                  map.set(item.account, item);
                }
              });
              state.infoList = [...map.values()];

              const map1 = state.infoList.map((x) => {
                if (x.account == state.loginForm.account) {
                  x.time++;
                }
                return x;
              });

              sessionStorage.setItem("loginUser", JSON.stringify(state.infoList));

              // 验证失败-弹出错误提示
              ElMessage.error("用户名或密码错误");
            }
          } catch (error) {
            console.log(error);
          }

          state.loading = false; // 请求之后 失败成功都关闭登录按钮加载中状态
          form.value.resetFields(); // 重置表单
        }
      });
    };

    const thirdThing = async (value) => {
      let num = state.thirdList.indexOf(Number(router.currentRoute.value.query.tp));

      try {
        if (num == 0) {
          const { data: res } = await thirdLogin({
            tk: value,
          });

          if (res.code == 0) {
            store.commit("setLoginInfo", res.data);
            store.commit("setSsoToken", res.data.userInfo.ssoToken);

            router.push("/sso");
            ElMessage.success("登录成功");
          } else {
            ElMessage.warning("登录失败，将为你转为普通登录");
          }
        } else if (num == 1) {
          const { data: res } = await thirdTwo({
            appId: router.currentRoute.value.query.appid,
            code: router.currentRoute.value.query.code,
            state: router.currentRoute.value.query.state,
          });

          if (res.code == 0) {
            store.commit("setLoginInfo", res.data);
            store.commit("setSsoToken", res.data.userInfo.ssoToken);

            router.push("/sso");
            ElMessage.success("登录成功");
          } else {
            ElMessage.warning("登录失败，将为你转为普通登录");
          }
        }
      } catch (error) {
        ElMessage.warning("登录失败，将为你转为普通登录");
      } finally {
        setTimeout(() => {
          state.showCard = false;
          // state.loading = false;
        }, 2000);
      }
    };

    onMounted(() => {
      if (getUname()) {
        // 如果cookie中有用户名 则表单勾选上记住用户名
        state.rememberChecked = true;
        state.loginForm.account = getUname();
      }
    });
    return {
      form,
      clickImg,
      formRules,
      thirdThing,
      loginSubmit,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
@margin-border: 45px; // 表单竖线左右margin
@margin-btn: 35px; // 登录按钮宽度 -margin

// 页面大盒子
.login-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("~@/assets/Big-data001.jpg") center no-repeat;
  background-size: 100% 100%;

  .login-card {
    position: absolute;
    top: 27vh;
    right: 25vw;
    width: 50vw;
    height: 50vh;
    background: url("~@/assets/third.gif") center no-repeat;
    background-size: 100% 100%;
    border-radius: 2%;
    font-size: 40px;
    font-family: STXingkai, STKaiti, SimHei;
    color: #f3f3f3;
  }

  // 卡片
  .el-card {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 0;
    box-shadow: none;
    width: 100%;
    height: 60%;

    // 卡片头部
    :deep .el-card__header {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      padding: 0;
      border: 0;
      height: 110px;
      line-height: 100px;

      //文字
      .title {
        font-family: STXingkai, STKaiti, SimHei;
        font-size: 75px;
        color: #fff;
        letter-spacing: 1px;
        margin: 0;
        margin-bottom: 20px;
        white-space: nowrap;
      }
    }

    // 登录区域
    :deep .el-card__body {
      position: relative;
      display: flex;
      padding: 0;
      height: 380px;
      background: #fff;
      width: 43%;
      margin: auto;
      // height: calc(100% - 150px);

      .changeLog {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        font-size: 20px;
        color: #fff;
        border: 1px solid #2970fc;
        background-color: #2970fc;
        text-decoration: none;
      }

      // 左侧
      .logo-box {
        width: 43%;
        height: 100%;
        background: url("~@/assets/Big-data002.jpg") center no-repeat;
        background-size: cover;
      }

      // 右侧logo表单
      .logo-form {
        width: 57%;
        height: 100%;
        background-color: #fff;
        // background-color: pink;

        &.lzclass {
          // 柳州---绿色
          .el-form {
            // 登录行
            .el-form-item {
              .el-button {
                background-image: linear-gradient(
                  to right,
                  rgb(159, 224, 140),
                  rgb(72, 215, 135)
                );
              }
            }

            .el-input__icon {
              color: #75d7a1 !important;
            }

            .el-checkbox__label {
              color: #48d787;
            }

            .el-checkbox__inner {
              border-color: #48d787;
            }
          }

          .el-input__inner {
            border: 1px solid #75d7a1;
            background-color: rgba(217, 253, 212, 0.7);
          }
        }

        h1 {
          display: flex;
          align-items: center;
          height: 75px;
          margin: 20px 0;
          padding: 0 70px;
          background-color: #fff;

          img {
            width: 100%;
          }
        }

        .form-left {
          // max-height: calc(100% - 115px);
          // overflow-y: auto;
          // background-color: pink;
          // 登录行
          .el-form-item {
            .el-form-item__content {
              display: flex;
              align-items: center;
              padding: 0 70px;
            }

            .el-input {
              color: #2970fc;
              padding-bottom: 0 !important;
            }

            .el-button {
              width: 100%;
              height: 47px;
              border: none;
              border-radius: 22px;
              background-image: linear-gradient(
                to right,
                rgb(121, 200, 252),
                rgb(48, 113, 255)
              );
            }
          }

          .el-form-item__error {
            // padding:5px 90px;
            top: 101%;
            left: 20%;
          }

          .el-input__prefix {
            top: 7px;
            margin-left: 6px;
          }

          .el-input__icon {
            font-size: 20px;
            color: #53b5fe !important;
          }

          .el-checkbox__label {
            color: #53b5fe;
          }

          .el-checkbox__inner {
            border-color: #53b5fe;
          }

          .el-form-item:nth-child(2) {
            margin-bottom: 10px;
          }

          .el-form-item:nth-child(3) {
            margin-bottom: 0;
          }
        }

        .el-input__inner {
          height: 47px;
          border-radius: 22px;
          padding-left: 40px;
          border: 1px solid #8ab9ff;
          background-color: rgba(241, 248, 255, 0.7);

          &::placeholder {
            color: #b0b0b3;
            font-size: 18px;
          }
        }
      }
    }
  }

  // 可选登录区域
  .img-wrap {
    display: flex;
    justify-content: center;
    width: 80%;
    height: 170px;
    position: absolute;
    top: calc(250px + 46%);
    left: 50%;
    transform: translateX(-50%);

    &.img-wrap1 {
      width: 43%;
    }

    img {
      position: relative;
      width: 26.5%;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      cursor: pointer;

      &.img-1 {
        width: 100%;
      }
    }
  }
}
</style>
